// Define estilos para una fila donde se mostraran dos campos de un formulario
.row-field {
	display: flex;
	justify-content: space-between;
	margin-top: 5px;
	margin-bottom: 5px;
	flex-wrap: wrap;
	width: 80%;
	margin-left: 10%;

	&.one-item {
		justify-content: flex-start;
	}
}


// Especifica estilos para div.field-wrapper usado para mostrar los campos en los formularios
// y los errores correspondientes

.field-wrapper {
	height: auto;
	display: flex;
	flex-direction: column;
	width: 45%;
	min-height: 84px;

	mat-slide-toggle {
		margin-top: auto;
		margin-bottom: auto;
	}

	&.row {
		flex-direction: row !important;
		justify-content: space-around;

		.mat-checkbox {
			margin-top: 10px !important;
		}

	}
}

.form-actions {

	display: flex;
	justify-content: center;
	align-items: center;

	button {
		height: 35px !important;
	}
}
