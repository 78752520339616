@import "variables";

.table-container {
	font-family: $font-family-base;

	&.sticky {
		height: 400px;
		overflow: auto;
	}

	width: 100%;
	border-radius: 4px;
	//box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
	background-color: #ffffff;
	//margin-top: 20px;
	//border: 1px solid #cccccc;

	.table-header {
		display: flex;
		justify-content: space-between;
	}

	.table-info {
		height: 59px;
		display: flex;
		justify-content: space-between;
		align-items: center;

		& > span {
			font-family: $font-family-base !important;
			height: 20px;
			font-size: 16px;
			color: #888888;
			margin-left: 24px;
		}

		& > div {
			cursor: pointer;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 116px;
			height: 36px;
			opacity: 0.5;
			border-radius: 4px;
			border: solid 1px #cccccc;
			background-color: #ffffff;
			margin-right: 11px;
		}
	}

	.table-options {
		padding-top: 16px;
		padding-bottom: 16px;

		& > *:last-child {
			margin-right: 16px;
		}

		&.end {
			display: flex;
			justify-content: flex-end;
		}
	}

	table {
		width: 100%;
		font-family: $font-family-base !important;
		box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);

		thead {

			tr {
				background-color: #2b0d61 !important;
				height: 31px !important;

				th {
					color: white !important;
					font-size: 12px !important;
					height: 30px !important;
					font-family: $font-family-base !important;
					text-align: center !important;

					&.text-start {
						text-align: start !important;
					}

					&.text-end {
						text-align: end !important;
					}
				}
			}

		}

		tbody {
			tr {

				font-size: 14px;
				color: #333333;
				height: 30px !important;

				&:hover {
					background: #e2e2e2;
				}

				&.selected {
					background-color: rgba(42, 63, 110, 0.05) !important;
				}

				&.pointer {
					cursor: pointer;
				}

				td {
					text-align: center !important;

					&.text-start {
						text-align: start !important;
					}

					&.text-end {
						text-align: end !important;
					}
				}
			}
		}

		&.lt-md {
			border-collapse: collapse;
			table-layout: fixed;
			border: 0;

			thead {
				border: none;
				clip: rect(0 0 0 0);
				height: 1px;
				margin: -1px;
				overflow: hidden;
				padding: 0;
				position: absolute;
				width: 1px;
			}

			tr {
				border-bottom: .625em solid #ddd;
				display: block;
				height: auto !important;
				cursor: pointer;
				font-size: 14px;
				color: #333333;
			}


			td {
				font-family: $font-family-base !important;
				display: flex;
				justify-content: space-between;
				padding: .625em;
				width: inherit !important;
			}

			td::before {
				/*
				* aria-label has no advantage, it won't be read inside a table
				content: attr(aria-label);
				*/
				content: attr(data-label);
				float: left;
				font-size: 16px;
				font-weight: 600;
				color: #888888;
			}

			td:last-child {
				border-bottom: 0;
			}
		}

		//
		//.mat-icon {
		//  color: #cccccc !important;
		//}
	}

	.table-no-results {
		display: flex;
		margin-top: 5%;
		align-items: center;
		min-height: 500px;
		flex-direction: column;

		img {
			width: 200px;
		}

		p {
			width: 339px;
			height: 46px;
			font-size: 18px;
			font-weight: bold;
			text-align: center;
			color: #4c566a;
		}
	}

	.mat-paginator {
		border-top: 1px solid #dddddd !important;
	}
}

