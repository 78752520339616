@import "variables";

.btn {
	font-family: $font-family-base;
	height: 24px;
	line-height: 10px !important;
	font-size: 12px;
	margin-top: 3px;
	margin-right: 4px !important;
	margin-left: 4px !important;
	min-width: 110px !important;

	&.btn-green {
		background-color: green !important;
		color: white !important;
	}

	&.disabled {
		cursor: default !important;
		box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12) !important;
		background-color: rgba(0, 0, 0, 0.12) !important;
		background-color: rgba(0, 0, 0, 0.26) !important;
	}
}
