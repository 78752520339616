/*!
 *
 * Yoan Asdrubal Quintana Ramirez.
 *  12/6/2019
 *
 */
/**
	Modifica los estilos de los tabs en un mat-tab-group, se utiliza de la forma
	<mat-tab-group class="custom-tab-group-styles">
			<mat-tab label="tab1">
				Contenido de la ficha
			</mat-tab>
			<mat-tab label="Contrato">
				Contenido de contrato
			</mat-tab>
		</mat-tab-group>
 */
@import "variables";

mat-tab-group.custom-tab-group-styles {
	font-family: $font-family-base !important;
	margin-top: 0;
	margin-left: 0;

	.custom-tab-content {
		margin-top: 40px;

		.table-container {
			width: calc(100% - 2px);
		}
	}

	.mat-tab-header {
		border-bottom: none !important;
	}

	div.mat-tab-labels {
		height: 40px;
	}

	div.mat-tab-label-container {
		//border-bottom: 1px solid #cccccc;

		div.mat-tab-label-content {
			font-family: $font-family-base !important;
			font-size: $font-size-base !important;
			line-height: 1;
			letter-spacing: 0.2px;
			text-align: center;
			color: rgba(0, 0, 0, 0.6);
		}

		div.mat-tab-label {
			opacity: 1;
			font-weight: bold;
			color: #2a3f6e;
			height: 40px;

			&.mat-tab-label-active {

				background: #ed6e00 !important;

				div.mat-tab-label-content {
					font-weight: bold;
					color: white;
				}
			}

			&.mat-tab-label.mat-tab-disabled {
				background: #9ea0a0d9 !important;
			}
		}
	}

	&.label-125 {
		div.mat-tab-label {
			min-width: 125px;
		}
	}

	&.no-ink-bar {
		mat-ink-bar {
			visibility: hidden !important;
			width: 0 !important;
		}
	}

	&.bordered {
		div.mat-tab-label-container {
			div.mat-tab-label-content {
			}

			div.mat-tab-label {
				border: 1px solid black;
			}
		}
	}

	&.spaced {
		div.mat-tab-label-container {
			div.mat-tab-label {
				margin-left: 1px;
				margin-right: 1px;
			}
		}
	}

	&.no-spaced {
		div.mat-tab-label-container {
			div.mat-tab-label {
				margin-left: 0 !important;
				margin-right: 0 !important;
			}
		}
	}

	&.active-warn {
		div.mat-tab-label-container {
			div.mat-tab-label {
				&.mat-tab-label-active {
					background: #ed6e00 !important;

					div.mat-tab-label-content {
						font-weight: bold;
						color: white;
					}
				}
			}
		}
	}

	&.active-primary {
		div.mat-tab-label-container {
			div.mat-tab-label {
				&.mat-tab-label-active {
					background: #2b0d61 !important;

					div.mat-tab-label-content {
						font-weight: bold;
						color: white;
					}
				}
			}
		}
	}
}

