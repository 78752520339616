/*
 *
 *   EGRET - Angular Material Design Admin Template
 *
 *
*/

// Third pirty style files
// Sidebar Scroll
@import '~perfect-scrollbar/css/perfect-scrollbar.css';
//  star-rating styles (Used in Shop app)
// Data Table

/* @import '~@swimlane/ngx-datatable/index.css';
@import '~@swimlane/ngx-datatable/themes/material.css';
@import '~@swimlane/ngx-datatable/assets/icons.css'; */

// Drag and Drop
// Calendar

/* @import '~angular-calendar/css/angular-calendar.css'; */

// User tour

/*@import '~hopscotch/dist/css/hopscotch.min.css';*/

// Main Variables File
@import "scss/variables";
// Open _themes.scss and remove unnecessary theme files
@import "scss/themes";
// Open _main.scss and keep files which are required for your current layout.
@import "scss/main";
// Estilos asociados a los botones
@import "scss/buttons";
// Estilos asociados a las tablas
@import "scss/tables";
// Estilos asociados a los formularios
@import "scss/form";
// Estilos asociados a los widgets
@import "scss/widget";
// Estilos asociados a las notificaciones
@import "scss/alert";
// Estilos generales para las tablas handsontable
// @import '~handsontable/dist/handsontable.full.css';
// Estilos generales para los tabs
@import "scss/mat-tab-group";