/*!
 *
 * Yoan Asdrubal Quintana Ramirez.
 *  1/10/2019
 *
 */


mat-form-field, widget-autocomplete, widget-date-picker, widget-period-picker, widget-year-picker, widget-field, widget-autocomplete-multiple {

	//border: solid 1px rgba(42, 63, 110, 0.6);

	.mat-form-field-wrapper {
		width: 100%;
		padding-top: 0 !important;
		padding-bottom: 0 !important;
		margin: 0 !important;

		.mat-form-field-underline {
			display: none;
		}

		.mat-form-field-subscript-wrapper {
			display: none;
		}

		.mat-form-field-flex {
			display: flex;
			align-items: center;
			//min-height: 56px !important;
			margin-top: 0 !important;

			.mat-form-field-outline {
				opacity: 1 !important;
				top: 0 !important;

				.mat-form-field-outline-start,
				.mat-form-field-outline-gap,
				.mat-form-field-outline-end {
					border-width: 1px !important;
				}

				.mat-form-field-outline-start {
					border-radius: 4px 0 0 4px !important;
				}

				.mat-form-field-outline-end {
					border-radius: 0 4px 4px 0 !important;
				}

			}

		}

		.mat-form-field-outline-thick {
			color: rgba(42, 63, 110, 0.6) !important;
		}
	}

	.mat-form-field-infix {
		border-top: 0 !important;

		.mat-form-field-label-wrapper {
			label {
				mat-label {
					color: rgba(42, 63, 110, 0.6) !important;
				}
			}
		}
	}

	.mat-form-field-suffix {
		top: 0 !important;
	}


	&:hover {
		.mat-form-field-label-wrapper {
			label {
				mat-label {
					color: #2a3f6e !important;
				}
			}
		}

		.mat-form-field-wrapper {
			.mat-form-field-flex {
				.mat-form-field-outline {
					color: #2a3f6e !important;
				}

				.mat-form-field-outline-thick {
					color: #2a3f6e !important;
				}
			}
		}

		.mat-datepicker-toggle, .mat-icon {
			color: #2a3f6e !important;
		}
	}

	&.mat-focused, .mat-focused {
		.mat-form-field-label-wrapper {
			label {
				mat-label {
					color: #2a3f6e !important;
				}
			}
		}

		.mat-form-field-wrapper {
			.mat-form-field-flex {
				.mat-form-field-outline {
					color: #2a3f6e !important;

					.mat-form-field-outline-start,
					.mat-form-field-outline-gap,
					.mat-form-field-outline-end {
						border-width: 2px !important;
					}
				}

				.mat-form-field-outline-thick {
					color: #2a3f6e !important;
				}
			}
		}

		.mat-datepicker-toggle, .mat-icon {
			color: #2a3f6e !important;
		}
	}

	&.ng-valid.ng-dirty {
		.mat-form-field-label-wrapper {
			label {
				mat-label {
					color: #2a3f6e !important;
				}
			}
		}

		.mat-form-field-wrapper {
			.mat-form-field-flex {
				.mat-form-field-outline {
					color: rgba(42, 63, 110, 0.6) !important;
				}

			}
		}

		.mat-datepicker-toggle, .mat-icon {
			color: #2a3f6e !important;
		}

	}

	&.ng-invalid.ng-dirty {
		.mat-form-field-infix {
			.mat-form-field-label-wrapper {
				label {
					mat-label {
						color: #d64541 !important;
					}
				}
			}
		}

		.mat-form-field-wrapper {
			.mat-form-field-flex {
				.mat-form-field-outline {
					color: #d64541 !important;

					.mat-form-field-outline-start,
					.mat-form-field-outline-gap,
					.mat-form-field-outline-end {
						border-width: 2px !important;
					}
				}

			}
		}

		.mat-datepicker-toggle, .mat-icon {
			color: #d64541 !important;
		}

	}

}

// Define estilos para una fila donde se mostraran dos campos de un formulario
.row-field {
	display: flex;
	justify-content: space-between;
	margin-top: 5px;
	margin-bottom: 5px;
	flex-wrap: wrap;
	width: 80%;
	margin-left: 10%;

	&.one-item {
		justify-content: flex-start;
	}
}


// Especifica estilos para div.field-wrapper usado para mostrar los campos en los formularios
// y los errores correspondientes

.field-wrapper {
	height: auto;
	display: flex;
	flex-direction: column;
	max-width: 400px;
	width: 45%;
	min-height: 84px;

	mat-slide-toggle {
		margin-top: auto;
		margin-bottom: auto;
	}

	&.row {
		flex-direction: row !important;
		justify-content: space-around;

		.mat-checkbox {
			margin-top: 10px !important;
		}

	}
}

mat-form-field,
widget-autocomplete > mat-form-field,
widget-date-picker > mat-form-field,
widget-period-picker > mat-form-field,
widget-year-picker > mat-form-field,
widget-field > mat-form-field,
widget-autocomplete-multiple > mat-form-field {
	border-bottom: solid 1px rgba(0, 0, 0, 0.42);
}
